import { render, staticRenderFns } from "./AuthorExtraInfoComponent.vue?vue&type=template&id=110d31ec&scoped=true&"
import script from "./AuthorExtraInfoComponent.vue?vue&type=script&lang=ts&"
export * from "./AuthorExtraInfoComponent.vue?vue&type=script&lang=ts&"
import style0 from "./AuthorExtraInfoComponent.vue?vue&type=style&index=0&id=110d31ec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "110d31ec",
  null
  
)

export default component.exports