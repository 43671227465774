var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Card',{directives:[{name:"show",rawName:"v-show",value:(_vm.author.coAuthors.length > 0),expression:"author.coAuthors.length > 0"}],attrs:{"title":'Coauthors (' + _vm.author.coAuthors.length + ')'}},[_c('BTableSimple',{attrs:{"small":"","striped":""}},[_c('BThead',{ref:"coauthors"},[_c('BTr',[_c('BTh',{staticClass:"d-none d-md-table-cell",class:_vm.headerClass.name,attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.sort('name')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sort('name')}}},[_vm._v(" Name ")]),_c('BTh',{staticClass:"text-center d-none d-md-table-cell",class:_vm.headerClass.totalCollaborations,attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.sort('totalCollaborations')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sort('totalCollaborations')}}},[_vm._v(" Collaborations ")])],1)],1),_c('BTbody',_vm._l((_vm.coAuthors),function(coAuthor,coAuthorIndex){return _c('BTr',{key:coAuthorIndex},[_c('BTd',[(_vm.isPublic)?_c('RouterLink',{attrs:{"to":{name: 'MRAuthorID', params: {'authorID': coAuthor.authorId}}}},[_c('div',[_c('div',{staticClass:"font-weight-bold d-md-none",domProps:{"innerHTML":_vm._s(coAuthor.name)}}),_c('div',{staticClass:"d-none d-md-block",domProps:{"innerHTML":_vm._s(coAuthor.name)}})])]):_c('RouterLink',{attrs:{"to":'../author?authorId=' + coAuthor.authorId}},[_c('div',{staticClass:"font-weight-bold d-md-none",domProps:{"innerHTML":_vm._s(coAuthor.name)}}),_c('div',{staticClass:"d-none d-md-block",domProps:{"innerHTML":_vm._s(coAuthor.name)}})])],1),_c('BTd',{staticClass:"text-right d-md-none d-xs-table-cell"},[_c('RouterLink',{attrs:{"to":{
                name: 'PublicationsSearch',
                query: {
                  query: `auid:${coAuthor.authorId} auid:${_vm.author.id}`,
                },
              }}},[_vm._v(" "+_vm._s(coAuthor.totalCollaborations)+" collabs ")])],1),_c('BTd',{staticClass:"text-center d-none d-md-table-cell"},[(_vm.isPublic)?_c('div',[_vm._v(" "+_vm._s(coAuthor.totalCollaborations)+" ")]):_c('RouterLink',{attrs:{"to":{
                name: 'PublicationsSearch',
                query: {
                  query: `auid:${coAuthor.authorId} auid:${_vm.author.id}`,
                },
              }}},[_vm._v(" "+_vm._s(coAuthor.totalCollaborations)+" ")])],1)],1)}),1)],1),(_vm.author.coAuthors.length > _vm.perPage)?_c('BButton',{staticClass:"mr-1",attrs:{"disabled":_vm.coAuthors.length === _vm.author.coAuthors.length,"variant":"primary","title":"Show more coauthors"},on:{"click":_vm.moreCoAuthors}},[_vm._v(" Show More ")]):_vm._e(),(_vm.author.coAuthors.length > _vm.perPage && _vm.coAuthors.length < _vm.author.coAuthors.length)?_c('BButton',{staticClass:"mr-1",attrs:{"variant":"primary","title":"Show all coauthors"},on:{"click":_vm.allCoAuthors}},[_vm._v(" Show All ")]):_vm._e(),(_vm.author.coAuthors.length > _vm.perPage && _vm.coAuthors.length === _vm.author.coAuthors.length)?_c('BButton',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.collapseCoAuthors}},[_vm._v(" Show "+_vm._s(_vm.perPage)+" ")]):_vm._e(),(_vm.author.coAuthors.length > _vm.perPage)?_c('BButton',{attrs:{"disabled":_vm.pageNumber === 1,"variant":"primary","title":"Show fewer coauthors"},on:{"click":_vm.lessCoAuthors}},[_vm._v(" Show Less ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }